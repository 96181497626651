/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ColumnFilter,
  GeneralStatus,
  LocationsDto,
  LocationsDtoListResponseModel,
  LocationsDtoModelResponse,
  SortType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Locations<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Locations
   * @name PostLocations
   * @request POST:/Locations/Add
   */
  postLocations = (data: LocationsDto, params: RequestParams = {}) =>
    this.request<LocationsDtoModelResponse, any>({
      path: `/Locations/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Locations
   * @name LoadList
   * @request GET:/Locations/Load
   */
  loadList = (
    query?: {
      Take?: number;
      Skip?: number;
      "OrderBy.Field"?: string;
      "OrderBy.Type"?: SortType;
      SearchTerm?: string;
      ColumnFilters?: ColumnFilter[];
      Status?: GeneralStatus;
      PracticeId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<LocationsDtoListResponseModel, any>({
      path: `/Locations/Load`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Locations
   * @name GetByIdList
   * @request GET:/Locations/GetById
   */
  getByIdList = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<LocationsDtoModelResponse, any>({
      path: `/Locations/GetById`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Locations
   * @name DeleteDelete
   * @request DELETE:/Locations/Delete
   */
  deleteDelete = (query?: { id?: number }, params: RequestParams = {}) =>
    this.request<LocationsDtoModelResponse, any>({
      path: `/Locations/Delete`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Locations
   * @name UpdateCreate
   * @request POST:/Locations/Update
   */
  updateCreate = (data: LocationsDto, params: RequestParams = {}) =>
    this.request<LocationsDtoModelResponse, any>({
      path: `/Locations/Update`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
