
export  class FilterBase {
   public Take: number;
   public Skip: number;
   public OrderBy: SortBy;
   public SearchTerm: string;
   public practiceId:number|null;

   
    constructor(take?: number, skip?: number) {
        if(take)
        this.Take = take;
        if(skip)
            this.Skip = skip;
    }
}
export class PracticeFilter extends FilterBase {
    ehrId: number | null;
    userId: number | null;
}
export   class SortBy{
    public Field:string;
    public Type:SortType;
}

export enum SortType{
    asc,
    desc
}